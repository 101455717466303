<template>
  <div id="blog-post">
    <div id="headerHome" class="col-sm-12 col-md-12 color-bg-1">
      <HeaderHome :name="user.fullname" />
    </div>

    <div id="res-contenido">
      <ResultHeader />
      <div class="res-contenido-inside">
        <ResultTab />
        <div class="sidebar-right">
         <!-- <SponsorSecW />-->
          <div class="widgets-cont-sb">
            <LastMatchesW />
            <LastGalleriesW />
            <!--<AddW />-->
          </div>
        </div>
      </div>
    </div>

    <div id="footerSection">
      <Footer />
    </div>
  </div>
</template>
<script>
import Footer from "../components/footer";
import ResultHeader from "../components/ResultHeader";
import ResultTab from "../components/ResultTab";
import SponsorSecW from "../components/widgets/SponsorSecW";
import LastMatchesW from "../components/widgets/LastMatchesW";
import LastGalleriesW from "../components/widgets/LastGalleriesW";
import AddW from "../components/widgets/AddW";
import HeaderHome from "../components/HeaderHome";
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  components: {
    HeaderHome,
    ResultHeader,
    ResultTab,
    SponsorSecW,
    LastGalleriesW,
    LastMatchesW,
    AddW,
    Footer,
  },
    async beforeMount() {
        let token = await this.$store.dispatch("admin/getToken");
        this.$store.dispatch("admin/decode", token);
    },
    computed: {
        user() {            
            return this.$store.getters['admin/getIdentity'];
        },

    },
    mounted() {
      localStorage.setItem('torneo','')
    },

  /**titulo pagina */
  watch: {
        $route: {
            immediate: true,
            handler(to, from) {
                document.title = to.meta.title || 'Resultados - Cimarrones de Sonora';
            }
        },
    }
};
</script>
