<template>
  <div class="stats-lineup-cont">
    <div class="stlc-header">
      <h1>ESTADÍSTICAS Y ALINEACIÓN </h1>
    </div>


    <div class="stats-cont" v-if="statsmx.estadisticas == 'No se encontraron coincidencias'">
      **Estadísticas no disponibles
    </div>
    <div class="stats-cont" v-else>
      <div class="rol-stat-cont">
        <h5>Goles</h5>
        <div class="rol-stat" :class="{'reverse': istrue}">
          <h6 class="res local" >{{statsmx.estadisticas.goles_c}}</h6>
          <div class="rol"  :class="{'reverse': istrue}">
            <div class="rol local"  id="bar-c" 
            :style="
            {'width': Loc(statsmx.estadisticas.goles_c,statsmx.estadisticas.goles_o),
            'border-radius': RadLoc(statsmx.estadisticas.goles_c,statsmx.estadisticas.goles_o)}"></div>
            <div class="rol visita" id="bar-o"
             :style="
            {'width': Vis(statsmx.estadisticas.goles_o,statsmx.estadisticas.goles_c),
            'border-radius': RadVis(statsmx.estadisticas.goles_o,statsmx.estadisticas.goles_c)}"></div>
          </div>
          <h6 class="res visita" >{{statsmx.estadisticas.goles_o}}</h6>
        </div>
      </div>

      <div class="rol-stat-cont">
        <h5>Tiros a Gol</h5>
        <div class="rol-stat" :class="{'reverse': istrue}">
          <h6 class="res local">{{statsmx.estadisticas.tag_c}}</h6>
          <div class="rol " :class="{'reverse': istrue}">
            <div class="rol local" 
            :style="
            {'width': Loc(statsmx.estadisticas.tag_c,statsmx.estadisticas.tag_o),
            'border-radius': RadLoc(statsmx.estadisticas.tag_c,statsmx.estadisticas.tag_o)}"></div>
            <div class="rol visita" 
             :style="
            {'width': Vis(statsmx.estadisticas.tag_o,statsmx.estadisticas.tag_c),
            'border-radius': RadVis(statsmx.estadisticas.tag_o,statsmx.estadisticas.tag_c)}"></div>
          </div>
          <h6 class="res visita">{{statsmx.estadisticas.tag_o}}</h6>
        </div>
      </div>

      <div class="rol-stat-cont">
        <h5>Tiros de Esquina</h5>
        <div class="rol-stat" :class="{'reverse': istrue}">
          <h6 class="res local">{{statsmx.estadisticas.tde_c}}</h6>
          <div class="rol " :class="{'reverse': istrue}">
            <div class="rol local" 
            :style="
            {'width': Loc(statsmx.estadisticas.tde_c,statsmx.estadisticas.tde_o),
            'border-radius': RadLoc(statsmx.estadisticas.tde_c,statsmx.estadisticas.tde_o)}"></div>
            <div class="rol visita" 
             :style="
            {'width': Vis(statsmx.estadisticas.tde_o,statsmx.estadisticas.tde_c),
            'border-radius': RadVis(statsmx.estadisticas.tde_o,statsmx.estadisticas.tde_c)}"></div>
          </div>
          <h6 class="res visita">{{statsmx.estadisticas.tde_o}}</h6>
        </div>
      </div>

      <div class="rol-stat-cont">
        <h5>Fuera de Lugar</h5>
        <div class="rol-stat" :class="{'reverse': istrue}">
          <h6 class="res local">{{statsmx.estadisticas.fl_c}}</h6>
          <div class="rol " :class="{'reverse': istrue}">
            <div class="rol local" 
            :style="
            {'width': Loc(statsmx.estadisticas.fl_c,statsmx.estadisticas.fl_o),
            'border-radius': RadLoc(statsmx.estadisticas.fl_c,statsmx.estadisticas.fl_o)}"></div>
            <div class="rol visita" 
             :style="
            {'width': Vis(statsmx.estadisticas.fl_o,statsmx.estadisticas.fl_c),
            'border-radius': RadVis(statsmx.estadisticas.fl_o,statsmx.estadisticas.fl_c)}"></div>
          </div>
          <h6 class="res visita">{{statsmx.estadisticas.fl_o}}</h6>
        </div>
      </div>

      <div class="rol-stat-cont">
        <h5>Faltas</h5>
        <div class="rol-stat" :class="{'reverse': istrue}">
          <h6 class="res local">{{statsmx.estadisticas.fts_c}}</h6>
          <div class="rol " :class="{'reverse': istrue}">
            <div class="rol local" 
            :style="
            {'width': Loc(statsmx.estadisticas.fts_c,statsmx.estadisticas.fts_o),
            'border-radius': RadLoc(statsmx.estadisticas.fts_c,statsmx.estadisticas.fts_o)}"></div>
            <div class="rol visita" 
             :style="
            {'width': Vis(statsmx.estadisticas.fts_o,statsmx.estadisticas.fts_c),
            'border-radius': RadVis(statsmx.estadisticas.fts_o,statsmx.estadisticas.fts_c)}"></div>
          </div>
          <h6 class="res visita">{{statsmx.estadisticas.fts_o}}</h6>
        </div>
      </div>

      <div class="rol-stat-cont">
        <h5>Tarjetas Rojas</h5>
        <div class="rol-stat" :class="{'reverse': istrue}">
          <h6 class="res local">{{statsmx.estadisticas.tr_c}}</h6>
          <div class="rol " :class="{'reverse': istrue}">
            <div class="rol local"
            :style="
            {'width': Loc(statsmx.estadisticas.tr_c,statsmx.estadisticas.tr_o),
            'border-radius': RadLoc(statsmx.estadisticas.tr_c,statsmx.estadisticas.tr_o)}"></div>
            <div class="rol visita" 
             :style="
            {'width': Vis(statsmx.estadisticas.tr_o,statsmx.estadisticas.tr_c),
            'border-radius': RadVis(statsmx.estadisticas.tr_o,statsmx.estadisticas.tr_c)}"></div>
          </div>
          <h6 class="res visita">{{statsmx.estadisticas.tr_o}}</h6>
        </div>
      </div>

      <div class="rol-stat-cont">
        <h5>Tarjetas Amarillas</h5>
        <div class="rol-stat" :class="{'reverse': istrue}">
          <h6 class="res local">{{statsmx.estadisticas.ta_c}}</h6>
          <div class="rol " :class="{'reverse': istrue}">
            <div class="rol local" 
            :style="
            {'width': Loc(statsmx.estadisticas.ta_c,statsmx.estadisticas.ta_o),
            'border-radius': RadLoc(statsmx.estadisticas.ta_c,statsmx.estadisticas.ta_o)}"></div>
            <div class="rol visita" 
             :style="
            {'width': Vis(statsmx.estadisticas.ta_o,statsmx.estadisticas.ta_c),
            'border-radius': RadVis(statsmx.estadisticas.ta_o,statsmx.estadisticas.ta_c)}"></div>
          </div>
          <h6 class="res visita">{{statsmx.estadisticas.ta_o}}</h6>
        </div>
      </div>
    </div>


    <div class="lineup">
      <div class="teams">
        <div class="team-cont">
          <img src="../assets/Cimarrones-mx.png" alt="logo" class="logo-team-lup" v-if="statsmx.type == 'Local'"/>
          <img :src="url+'/get-calendar-image/'+statsmx.image" alt="logo" class="logo-team-lup" v-else/>
          <h1 class="name-team-lup">{{alineaciones.local.titulares[0].nombreClub}}</h1>
        </div>
        <div class="team-cont">
          <img  src="../assets/Cimarrones-mx.png" alt="logo" class="logo-team-lup" v-if="statsmx.type == 'Visitante'"/>
          <img :src="url+'/get-calendar-image/'+statsmx.image" alt="logo" class="logo-team-lup" v-else/>
          <h1 class="name-team-lup">{{alineaciones.visitante.titulares[0].nombreClub}}</h1>
        </div>
      </div>


      <div class="lineup-players">
        <div class="local-lineup">
          <div class="players-cont" v-for="(alineacion, key) in alineaciones.local.titulares" :key="key">
            <h1 class="number-player">{{alineacion.numeroCamiseta}}</h1>
            <img :src="alineacion.foto" alt="player" class="player-footage" />
            <div class="player-name-pos-cont">
              <h3 class="player-name"> {{alineacion.nombreJugador + ' ' +alineacion.apellidoPaterno + ' ' +alineacion.apellidoMaterno}}</h3>
              <h5 class="player-pos">{{alineacion.posicion}}</h5>
            </div>
          </div>
        </div>


        <div class="away-lineup">
          <div class="players-cont" v-for="(alineacion, key) in alineaciones.visitante.titulares" :key="key">
            <h1 class="number-player">{{alineacion.numeroCamiseta}}</h1>
            <img :src="alineacion.foto" alt="player" class="player-footage" />
            <div class="player-name-pos-cont">
              <h3 class="player-name">{{alineacion.nombreJugador + ' ' +alineacion.apellidoPaterno + ' ' +alineacion.apellidoMaterno}}</h3>
              <h5 class="player-pos">{{alineacion.posicion}}</h5>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="lineup-tab">
        <Tabs>
    <TabItem>
      <template #name>
        <div class="team-cont">
          <img src="../assets/Cimarrones-mx.png" alt="logo" class="logo-team-lup"  v-if="statsmx.stadium == 'Héroe de Nacozari'"/>
          <img :src="url+'/get-calendar-image/'+statsmx.image" alt="logo" class="logo-team-lup"  v-else/>
          <h1 class="name-team-lup">{{alineaciones.local.titulares[0].nombreClub}}</h1>
        </div>
      </template>

      <div class="lu-tab">
        <div class="local-lineup">
          <div class="players-cont" v-for="(alineacion, key) in alineaciones.local.titulares" :key="key">
            <h1 class="number-player">{{alineacion.numeroCamiseta}}</h1>
            <img :src="alineacion.foto" alt="player" class="player-footage" />
            <div class="player-name-pos-cont">
              <h3 class="player-name">{{alineacion.nombreJugador + ' ' +alineacion.apellidoPaterno + ' ' +alineacion.apellidoMaterno}}</h3>
              <h5 class="player-pos">{{alineacion.posicion}}</h5>
            </div>
          </div>
        </div>
      </div>
    </TabItem>
    <TabItem>
      <template #name>
        <!--dssad-->
        <div class="team-cont">
          <img :src="url+'/get-calendar-image/'+statsmx.image" alt="logo" class="logo-team-lup"  v-if="statsmx.stadium == 'Héroe de Nacozari'"/>
          <img src="../assets/Cimarrones-mx.png" alt="logo" class="logo-team-lup"  v-else/>
          <h1 class="name-team-lup">{{alineaciones.visitante.titulares[0].nombreClub}}</h1>
        </div>
      </template>

      <div class="lu-tab">
        <div class="away-lineup">
          <div class="players-cont" v-for="(alineacion, key) in alineaciones.visitante.titulares" :key="key">
            <h1 class="number-player">{{alineacion.numeroCamiseta}}</h1>
            <img :src="alineacion.foto" alt="player" class="player-footage" />
            <div class="player-name-pos-cont">
              <h3 class="player-name">{{alineacion.nombreJugador + ' ' +alineacion.apellidoPaterno + ' ' +alineacion.apellidoMaterno}}</h3>
              <h5 class="player-pos">{{alineacion.posicion}}</h5>
            </div>
          </div>
        </div>
      </div>
    </TabItem>
  </Tabs>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import es from "dayjs/locale/es";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
export default {
data() {
    return {
      dataId: 0,
      resC:0,
      resO:0,
      dimC: 50,
      dimO: 50,
      var1:false,
      cien:false,
      arr: JSON.parse(localStorage.getItem('arraym'))[0],
      idPar: 1200,
    }
  },
   async created(){
    
    let payload4 = {
      idTorneo: (this.arr.numtor),
      idDivision: (this.arr.numdiv),
      idTemporada: (this.arr.numtem),
      idClub: 10721,
      idJornada: (this.arr.numjor),
    };
    await this.getPartidosClub(payload4);

    let payload3 ={
      idTorneo: (this.arr.numtor),
         idDivision: (this.arr.numdiv),
         idJornada: (this.arr.numjor),
    };
    await this.getPartidos(payload3)
    await this.findById2() 

      let payload5 ={
        idPartido: (this.idpartido)
      }
    await this.getAlineaciones(payload5)
    },
    computed:{
      partidos() {
        return this.$store.getters["partidos/data"];
      },
      partidosClub() {
        return this.$store.getters["partidos/data2"];
      },
      alineaciones(){
        return this.$store.getters["alineaciones/data"];
      },
      statsmx(){
      let partidos = this.$store.getters["calendar/data7"];
      return partidos;
      },
      istrue(){
      let partidos = this.$store.getters["calendar/data7"];      
      if(partidos.type == 'Visitante'){
        this.var1 = true
      }else if(partidos.type == 'Local'){
        this.var1 = false
      }
      return this.var1
      },
      resultL(){
       if(estadisticas.goles_c == '0'){

      }else if(estadisticas.goles_c == '1'){
        
      }       
      },
      numberJornada(){
      let partidos = this.$store.getters["calendar/data7"];
      let match = partidos.typegame;
      let jornada = (match || "").split(' ');
      return jornada[1];
      },
      url() {
        return this.$store.getters["main/baseURL"];
      },
      idpartido() {
        let arr1 = this.partidosClub;
        let res = 0;
        if(Array.isArray(arr1)){
          for (let i = 0; i < arr1.length; i++) {
            if(arr1[i].idJornada == this.arr.numjor){
              res = (arr1[i].idPartido)  
            } 
          }           
        } 
        return res;
      },
    },
    /*async mounted() { 
      let payload5 ={
        idPartido: (this.idpartido)
      }
    await this.getAlineaciones(payload5)
    },*/
    methods:{
    ...mapActions('alineaciones', ['getAlineaciones']),
    ...mapActions("partidos", ["getPartidos"]),
    ...mapActions("partidos", ["getPartidosClub"]),
    ...mapActions("calendar", ["findById2"]),

    splitSpace(value){
      return value.split(' ')
    },
    isReverse(type){
      if(type == 'Visitante'){
        this.var1 == true
      }else if(type == 'Local'){
        this.var1 == false
      }
    },
    Loc(loc, vis){
      let l = Number(loc)
      let v = Number(vis)
      if(l == 0 && v >= 1){
        return '0'
      }else if(l >= 1 && v == 0){
        return '100%'
      }else if(l >= 1 && v > l){
        return '33.3%'
      }else if(l > v && v >= 1){
        return '66.6%'
      }else if(l >= 0 && v >= 0){
        return '50%'
      }
    },
    Vis(vis, loc){
      let v = Number(vis)
      let l = Number(loc)
      if(v == 0 && l >= 1){
        return '0'
      }else if(v >= 1 && l == 0){
        return '100%'
      }else if(v >= 1 && l > v){
        return '33.3%'
      }else if(v > l && l >= 1){
        return '66.6%'
      }else if(v >= 0 && l >= 0){
        return '50%'
      }
    },

    RadLoc(loc, vis){
      let l = Number(loc)
      let v = Number(vis)
      if(l >= 1 && v == 0){
        return '2vw!important'
      }
    },
    RadVis(vis, loc){
      let v = Number(vis)
      let l = Number(loc)
      if(v >= 1 && l == 0){
        return '2vw!important'
      }
    },

    findById2: async function () {
      let id = this.$route.params.id
      let result = await this.$store.dispatch("calendar/findById2", {
        option: "calendar","id": id
      });
      //console.log(result)
      this.info = result;
      return result;
    },

    changeNumber(){
      return this.dataId = this.getIdPartido;
    }


    }
};
</script>

<style>
.reverse{
  flex-direction: row-reverse;
}#res-contenido .rol.reverse .rol.local {
    background: #b70d1d;
    width: 50%;
    border-radius: 0 2vw 2vw 0!important;
}#res-contenido .rol.reverse  .rol.visita {
    background: #032a4b;
    width: 50%;
    border-radius:2vw 0 0 2vw!important;
}
.cien{
  border-radius:2vw!important;
}
.cincuenta{
  width: 50%;
}.sesentaseis{
  width: 66.6%;
}.treintatres{
  width: 33.3%;
}.cero{
  width: 0%;
}
</style>
