<template>
<div>

    <GalleryImgModal v-show="isModalVisible12" @close="closeModal12">
      <template v-slot:body> 
        <img class="gallery-frame-img" :src="url+'/get-imagesgallery-image/'+modalData12.image" alt="image"/>
      </template>
    </GalleryImgModal>

      <div class="errore" v-if="this.gallerydata5.gallery == 'No se encontraron coincidencias'">
        <div class="en-construccion-cont">
            <img src="../assets/tarjeta-rojiza.png" alt="tarjetiza" class="tarjetiza">
            <h3 class="titulo-en-constr"><span class="red"> VUELVE EN UN RATO.</span> ESTAMOS CARGANDO LA INFORMACIÓN.</h3>
        </div>
      </div>
  <div class="result-gallery" v-for="gal in gallerydata5.gallery" :key="gal.id" v-else>
    <div class="cont-gal-title">
    <h1 class="result-gal-title">{{ gal.title }}.</h1>

    
          <div class="share-in">
            <p>COMPARTIR EN</p>
            <div class="sm-share-in">

              <ShareNetwork 
                network="facebook"
                :url="'https://cimarronesdesonora.xyz/blog/titulo/'"
                title="Galeria"
                description="Galeria"
                quote="Galeria"
                hashtags="vuejs,vite"
                :media="require('../assets/videobanner.png')"
              >
                <img src="../assets/fb-share.png" alt="fb" class="sm-share" />
              </ShareNetwork>
              <ShareNetwork
                network="twitter"
                :url="'https://cimarronesdesonora.xyz/blog/titulo/'"
                title="Galeria"
                description="Galeria"
                :media="require('../assets/videobanner.png')"
              >
                <img src="../assets/tw-share.png" alt="ins" class="sm-share" />
              </ShareNetwork>
              <ShareNetwork
                network="linkedin"
                :url="'https://cimarronesdesonora.xyz/blog/titulo/'"
                title="Galeria"
                description="Galeria"
                :media="require('../assets/videobanner.png')"
              >
                <img src="../assets/linkein.png" alt="ins" class="sm-share" />
              </ShareNetwork>
            </div>
          </div>
    </div>
    <!--<h5 class="date-g-link">HACE {{ newsdateago(gal.dateimg) }}</h5>-->
    
    <div class="galleries-cont">
      
    <div class="gallery-item" v-for="imadata in gal.imagenes" :key="imadata.id">
      <img class="gallery-frame" :src="url+'/get-imagesgallery-image/'+imadata.image" alt="image" @click="showModal12(imadata)"/>
    </div> 
    </div>
  </div>
</div>
</template>

<script>
import VueCoreVideoPlayer from "vue-core-video-player";
import Sponsor3 from "../components/widgets/SponsorSecW3";
import es from "dayjs/locale/es";
import relativeTime from "dayjs/plugin/relativeTime";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { mapActions } from "vuex";
import GalleryModal from "../components/GalleryModal.vue";
import GalleryImgModal from "../components/GalleryImgModal.vue";

export default {
  components: {
    VueCoreVideoPlayer,
    Sponsor3,GalleryModal,GalleryImgModal
  },
  data() {
    return {
      isModalVisible11: false,
      modalData11: null,
      isModalVisible12: false,
      modalData12: null,
    };
  },
  created() {
    this.findById();
  },
  computed: {
    url() {
      return this.$store.getters["main/baseURL"];
    },
    gallerydata5() {
      return this.$store.getters["calendar2/data"];
    },
  },
  methods: {
    ...mapActions("calendar2", ["findById"]),

    showModal11(data) {
      this.isModalVisible11 = true;
      this.modalData11 = data;
    },
    closeModal11() {
      this.isModalVisible11 = false;
      this.modalData11 = null;
    },

    showModal12(data) {
      this.isModalVisible12 = true;
      this.modalData12 = data;
    },
    closeModal12() {
      this.isModalVisible12 = false;
      this.modalData12 = null;
    },

    dataTransform: function (date) {
      let fecha = date.split("/");
      let yy = fecha[2];
      let mm = fecha[1];
      let dd = fecha[0];
      return (fecha = yy + "-" + mm + "-" + dd);
    },
    dataSort: function (date) {
      let fecha = date.split("/");
      let yy = fecha[2];
      let mm = fecha[1];
      let dd = fecha[0];
      return (fecha = yy + "" + mm + "" + dd);
    },

    newsdateago: function (date) {
      const dayjs = require("dayjs");
      dayjs.extend(customParseFormat);
      dayjs.extend(relativeTime);
      let newsdata = dayjs(date, "YYYY-MM-DD").locale(es).fromNow(true);
      return newsdata;
    },


    findById: async function () {
      let idm = this.$route.params.id;
      let result = await this.$store.dispatch("calendar2/findById", {
        option: "calendar",
        id: idm,
      });
      //this.info = result;
      return result;
    },

    filterTitle: function (title) {
      let result = title.replaceAll(" ", "-");
      return result;
    },
  },
};
</script>
<style scoped>
.gallery-item img {
    width: 12.552vw;
    height: 12.552vw;
    margin-right: 0.6vw;
    margin-bottom: 0.6vw;
    transition: .25s;
    object-fit: cover;
    object-position: top;
}.gallery-item img:hover {
    transform: scale(1.1);
}


#res-contenido .result-gallery .share-in {
    border: none;
}#blog-post .result-gallery .sm-share-in a {
    line-height: 0;
    width: 2vw;
    height: 2vw;
    border: 1px solid #032a4b;
    border-radius: 2vw;
    display: flex;
    justify-content: center;
    align-items: center;
}#blog-post .result-gallery .sm-share-in {
    width: 7.552vw;
    display: flex;
    justify-content: space-between;
}#blog-post .result-gallery .sm-share-in a:hover {
    border-color: #032a4b!important;
}
.modal2-backdrop{
  top:auto;
}
@media (max-width: 768px){
.gallery-item img {
    width: 36.614vw;
    height: 36.614vw;
    margin-right: 0;
    margin-bottom: 1vw;
}.gallery-item {
    width: fit-content;
}}
</style>
