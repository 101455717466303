<template>
  <div>
    <div class="post-cont">
      <div class="errore posting" v-if="this.caldata.blog == 'No se encontraron coincidencias'">
        <div class="en-construccion-cont">
            <img src="../assets/tarjeta-rojiza.png" alt="tarjetiza" class="tarjetiza">
            <h3 class="titulo-en-constr"><span class="red"> VUELVE EN UN RATO.</span> ESTAMOS CARGANDO LA INFORMACIÓN.</h3>
        </div>
      </div>
      <div class="post-text-cont" v-else>
        <div class="authors-cont">
          <h5 class="autor-text">POR STAFF CIMARRONES</h5>
          <div class="share-in">
            <p>COMPARTIR EN</p>
            <div class="sm-share-in">

              <ShareNetwork 
                network="facebook"
                :url="'https://cimarronesdesonora.xyz/blog/titulo/' + filterTitle(this.caldata.blog.title)"
                :title="this.caldata.blog.title"
                description="Descripcion"
                :quote=" this.caldata.blog.title"
                hashtags="CimarronesFC"
                :media="require('../assets/videobanner.png')"
              >
                <img src="../assets/fb-share.png" alt="fb" class="sm-share" />
              </ShareNetwork>
              <ShareNetwork
                network="twitter"
                :url="'https://cimarronesdesonora.xyz/blog/titulo/' + filterTitle(this.caldata.blog.title)"
                :title="this.caldata.blog.title"
                hashtags="CimarronesFC"
                :media="require('../assets/videobanner.png')"
              >
                <img src="../assets/tw-share.png" alt="ins" class="sm-share" />
              </ShareNetwork>
              <ShareNetwork
                network="linkedin"
                :url="'https://cimarronesdesonora.xyz/blog/titulo/' + filterTitle(this.caldata.blog.title)"
                :media="require('../assets/videobanner.png')"
              >
                <img src="../assets/linkein.png" alt="ins" class="sm-share" />
              </ShareNetwork>
            </div>
          </div>
        </div>

        <div class="post-text">
          <h3 class="date-post">
            {{dataTransform(this.caldata.blog.dateblog)}}
           
          </h3>
          <h1 class="titulo-post-text">
            {{this.caldata.blog.title}} 
          </h1>
          <h5 class="autor-text">POR STAFF CIMARRONES</h5>
          <div class="post-cont" v-html="this.caldata.blog.content">

          </div>

            <div class="sm-share-in mobile">
              <h3 class="share-title">COMPARTIR EN </h3>
              <div class="cont-sm-s">
                <ShareNetwork 
                network="facebook"
                :url="'https://cimarronesdesonora.xyz/blog/titulo/' + filterTitle(this.caldata.blog.title)"
                :title="this.caldata.blog.title"
                description="Descripcion"
                :quote="this.caldata.blog.title"
                hashtags="CimarronesFC"
                :media="require('../assets/videobanner.png')"
              >
                <img src="../assets/fb-share.png" alt="fb" class="sm-share" />
              </ShareNetwork>
              <ShareNetwork
                network="twitter"
                :url="'https://cimarronesdesonora.xyz/blog/titulo/' + filterTitle(this.caldata.blog.title)"
                :title=" this.caldata.blog.title"
                hashtags="CimarronesFC"
                :media="require('../assets/videobanner.png')"
              >
                <img src="../assets/tw-share.png" alt="ins" class="sm-share" />
              </ShareNetwork>
              <ShareNetwork
                network="linkedin"
                :url="'https://cimarronesdesonora.xyz/blog/titulo/' + filterTitle(this.caldata.blog.title)"
                :media="require('../assets/videobanner.png')"
              >
                <img src="../assets/linkein.png" alt="ins" class="sm-share" />
              </ShareNetwork>
                </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script src="/dist/vue-social-sharing.js"></script>
<script>
import SponsorSecW from "../components/widgets/SponsorSecW";
import es from 'dayjs/locale/es'
import dayjs from "dayjs"
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { mapActions } from "vuex";

export default {
  components: {
    SponsorSecW,
  },
  data() {
    return {
      visita: false,
      type:'',
      idm: 0,
    };
  },
  /**async beforeMount() {
    let token = await this.$store.dispatch("admin/getToken");
    this.$store.dispatch("admin/decode", token);
  },**/
  created() {
    this.findById();
  },
  computed: {
    url() {
      return this.$store.getters["main/baseURL"];
    },
    caldata() {
      return this.$store.getters["calendar2/data"];
    },
    user() {
      return this.$store.getters["admin/getIdentity"];
    },
  },
  methods: {
    ...mapActions("calendar2", ["findById"]),

    visitaValidate: function() {
      this.type = this.calendar.type
      if(this.type  = "Visitante"){
        this.visita = !this.visita;
      }
      else if(this.type  = "Local"){
        this.visita = this.visita;
      }
    },
    
    dataTransform: function(descrp){
       const dayjs = require('dayjs');
       dayjs.extend(customParseFormat)
       let fa =  dayjs(descrp, 'YYYY-MM-DD').locale(es).format('D-MMMM-YYYY')
       let fa2 =  dayjs(descrp, 'YYYY-MM-DD').locale(es).format('dddd-MM-YYYY')
       let fecha = fa.split('-')
              let yy = fecha[2]
              let mm = fecha[1]
              let dd = fecha[0]
       let fecha2 = fa2.split('-')
              let dd2 = fecha2[0]
        return fecha= dd2 +' '+dd+' '+mm
    },  

    filterTitle: function(title){
        let result = title.replaceAll(' ','-')
        return result
    },


    findById: async function () {
      let idm = this.$route.params.id;
      let result = await this.$store.dispatch("calendar2/findById", {
        option: "calendar",
        id: idm,
      });
      //this.info = result;
      return result;
    },
  },
};
</script>


<style scoped>
@media (max-width:768px) {
  
#res-contenido .sm-share-in.mobile {
    margin: 6vw 3vw 0;
}
}
</style>