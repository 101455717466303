<template>
  <div class="last-matches-widget">
    <!--header ultimos resultados-->
    <div class="lresw-header">
      <div class="uno">
        <h3 class="t">ÚLTIMOS <span>RESULTADOS</span></h3>
      </div>
      <div class="due">
        <select name="matchday" id="matchday-lresw"  v-model="selected">
          <option disabled value="">Selecciona una opción</option>
          <option v-for="(jor, key) in jornadas" v-bind:key="key" v-bind:value="jor.numero">{{jor.nombre}}</option>
        </select>
      </div>
    </div>
    <!--resultados cont-->
    <div class="lresw-resultados-cont">
        <p>{{this.validationP}}</p>
        <div class="match-result-cont" v-for="(part, key) in partidos" :key="key" >
            <div class="uno" >
                <h6 class="team pc">
                    {{part.clubLocal}}
                </h6>
                <h6 class="team mo">
                    {{opnameMin(part.clubLocal)}}
                </h6>
                <img :src="part.clubLocalLogo" alt="" class="logo-team">
                <p v-if="selected > numUltimaJornada" class="versus-v">vs</p>
                <p v-else-if="part.idEstatusMinutoAMinuto != '7'" class="versus-v">vs</p>
                <div class="elekipo" style="display:flex; width: 4.5vw; align-items: center; justify-content: space-between" v-else>
                <h4 class="resultado-team">{{part.golLocal}}</h4>
                <h4 class="resultado-team">{{part.golVisita}}</h4>
                </div>
                <img :src="part.clubVisitaLogo" alt="" class="logo-team">
                <h6 class="team pc">
                    {{part.clubVisita}}
                </h6>
                <h6 class="team mo">
                    {{opnameMin(part.clubVisita)}}
                </h6>
            </div>
            <h6 class="ht-ft2"  v-if="selected > numUltimaJornada"></h6>
            <h6 class="ht-ft2"  v-else-if="part.idEstatusMinutoAMinuto != '7'"></h6>
            <h6 class="ht-ft2"  v-else>FT</h6>
        </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import es from 'dayjs/locale/es'
import dayjs from "dayjs"
import customParseFormat from 'dayjs/plugin/customParseFormat'

export default {
  data() {
    return {
      primero: 0,
      selected:0,
      updSelected:0,
      addSelected:false,
      validationP: '',
    };
  },
  updated() {
    if(this.selected != this.updSelected){
      this.updSelected = this.selected
      let payload3 = {
      "idTorneo": 1,
      "idDivision": 20,
      "idJornada": this.selected,
    };
    this.getPartidos1(payload3);
    }
  },
  
  async created() {

    let id = this.$route.params.id;
    let payload = {
      "id": id,
      "option": "calendar",
    };
    let partidosD = this.getAllInfo("calendario");

    let payload2 = {
      "idTorneo": 1,
      "idDivision": 20,
    };
    this.getJornadas(payload2);

    let payload7 ={
         "idTorneo":1,
         "idDivision":20,
    }
   await this.getUltimaJornada(payload7)

    let payload3 = {
      "idTorneo": 1,
      "idDivision": 20,
      "idJornada":  this.numUltimaJornada,
    };

    if (this.selected == 0 && this.updSelected == 0 ){
       this.$data.validationP == 'No hay partidos proximos'
    }else  if (this.updSelected != 0){
       this.$data.validationP == ''
    this.getPartidos1(payload3);   
    } 
  },


  computed: {
    url() {
      return this.$store.getters["main/baseURL"];
    },
    calendardata() {
      return this.$store.getters["calendar/data"];
    },
    jornadas() {
      return this.$store.getters["jornadas/data"];
    },
    partidos() {
      return this.$store.getters["partidos/data3"];
    },
    dataP(){
      return this.$store.getters["main/data"]; 
    },
    numUltimaJornada(){
        let ultima =this.$store.getters["ultimaJornada/ultimaJornada"];
        this.selected =   ultima
        this.updSelected =  ultima
        return ultima
    }
  },

  watch:{

    async numUltimaJornada(){
        let ultima =this.$store.getters["ultimaJornada/ultimaJornada"];
        this.selected =   ultima
        this.updSelected =  ultima
        await ultima
    }
  },
  methods: {
     ...mapActions("main", ["getAllInfo"]),
    ...mapActions("calendar", ["getCalendar1"]),
    ...mapActions("jornadas", ["getJornadas"]),
    ...mapActions("partidos", ["getPartidos1"]),
    ...mapActions('ultimaJornada', ['getUltimaJornada']),

    exa() {
      let xd = new Date();
      let fecha =
        xd.getFullYear() + "-" + xd.getDate() + "-" + (xd.getMonth() + 1);
      return fecha;
    },

    today() {
      var now = dayjs();
      return now.format("YYYY-MM-DD");
    },

    value(){
      return this.selected;
    },

    dataTransform: function (descrp) {
      let fecha = descrp.split("/");
      let yy = fecha[2];
      let mm = fecha[1];
      let dd = fecha[0];
      return (fecha = yy + "-" + mm + "-" + dd);
    },

    matchday: function (descrp) {
      let fecha = descrp.split("-");
      let dd = fecha[4];
      return (fecha = dd);
    },
    matchmonth: function (descrp) {
      const dayjs = require("dayjs");
      dayjs.extend(customParseFormat);
      let match = dayjs(descrp, "YYYY-MM-DD").locale(es).format("DD-MMM-YYYY");
      let fecha = match.split("-");
      let mm = fecha[1];
      return (fecha = mm);
    },

    date1: function (descrp) {
      const dayjs = require("dayjs");
      dayjs.extend(customParseFormat);
      let fa = dayjs(descrp, "YYYY-MM-DD").locale(es).format("DD-MM-YYYY");
      let fecha = fa.split("-");
      let yy = fecha[2];
      let mm = fecha[1];
      let dd = fecha[0];
      return (fecha = yy + "-" + mm + "-" + dd);
    },

    date2: function (descrp) {
      const dayjs = require("dayjs");
      dayjs.extend(customParseFormat);
      let fa = dayjs(descrp, "YYYY-MM-DD").locale(es).format("YYYY-MMM-DD");
      let fecha = fa.split("-");
      let mm = fecha[1];
      let dd = fecha[2];
      let fa2 = dayjs(descrp, "YYYY-MM-DD").locale(es).format("YYYY-MMM-ddd");
      let fecha2 = fa2.split("-");
      let dd2 = fecha2[2];
      return (fecha = dd2 + " " + dd + " " + mm);
    },

    opnameMin: function (opname) {
      if (opname == "Tapatío") {
        return "TAP"; 
      } else if (opname == "Alebrijes de Oaxaca") {
        return "OAX";
      } else if (opname == "Cimarrones de Sonora FC") {
        return "CIM";
      }  else if (opname == "Atlante") {
        return "ATL";
      } else if (opname == "Dorados") {
        return "DOR";
      } else if (opname == "Venados F.C.") {
        return "VEN";
      } else if (opname == "Club Atlético Morelia") {
        return "MOR";
      } else if (opname == "RAYA2 EXPANSIÓN ") {
        return "RAY";
      } else if (opname == "TM Futbol Club") {
        return "TAM";
      } else if (opname == "Pumas Tabasco") {
        return "PUM";
      } else if (opname == "Club Celaya") {
        return "CEL";
      } else if (opname == "Tepatitlán FC") {
        return "TEP";
      } else if (opname == "Tlaxcala F.C.") {
        return "TLX";
      } else if (opname == "Cancún FC") {
        return "CAN";
      } else if (opname == "Mineros de Zacatecas") {
        return "MIN";
      } else if (opname == "Leones Negros") {
        return "UDG";
      } else if (opname == "Correcaminos") {
        return "UAT";
      }
    },

    getCalendar1: async function () {
      let result = await this.$store.dispatch("calendar/getCalendar1", {
        option: "calendario",
      });
      //console.log(result)
      this.info = result;
      return result;
    },
  },
};
</script>

<style>
.pc{
  display:block;
}.mo{
  display:none;
}.versus-v {
  width: 4.5vw;
  text-align: center;
  margin: 0;
  font-size: 1vw;
  text-transform: uppercase;
  color: #032a4b;
  font-family: "Rajdhani";
}

@media (max-width: 768px){
.pc{
  display:none;
}.mo{
  display:block;
}.elekipo{
  width: 17.5vw!important;
}
}
</style>
