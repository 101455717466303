<template>
<div>
<div v-if="titulotab == 'VIDEO'">
  <Tabs noTouch="true">
    <TabItem id="primer-equipo" :style="{display: this.flexnone }">
      <template #name>
        <div
          class="bar-name-item res"
          id="tabactiva"
          v-bind:class="{ activa: isActive }"
          @click="alturaMaxima()"
        >
          <img :src="barres" alt="icon" class="icon" />
          <h5>RESUMEN</h5>
        </div>
      </template>

      <div class="first-tab" :style="{display: this.flexnone }">
        <ResultPostSection />
      </div>
    </TabItem>
    <TabItem>
      <template #name>
        <div class="bar-name-item est" @click="displayTab()">
          <img :src="barest" alt="icon" class="icon" />
          <h5>ESTADÍSTICAS Y ALINEACIÓN</h5>
        </div>
      </template>

      <div class="rs-tab">
        <ResultStats />
      </div>
    </TabItem>
    <TabItem>
      <template #name>
        <div class="bar-name-item gal" @click="displayTab()">
          <img :src="bargal" alt="icon" class="icon" />
          <h5>GALERÍA</h5>
        </div>
      </template>

      <div class="rs-tab galvid">
        <ResultGallery />
      </div>
    </TabItem>
    <TabItem>
      <template #name>
        <div class="bar-name-item vid" @click="displayTab()">
          <img :src="barvid" alt="icon" class="icon" />
          <h5>VIDEO</h5>
        </div>
      </template>

      <div class="rs-tab galvid">
        <ResultVideosGal />
      </div>
    </TabItem>
  </Tabs>
</div>
<div v-else-if="titulotab == 'EN VIVO'">
  <Tabs noTouch="true">
    <TabItem id="primer-equipo" :style="{display: this.flexnone }">
      <template #name>
        <div
          class="bar-name-item res"
          id="tabactiva"
          v-bind:class="{ activa: isActive }"
          @click="alturaMaxima()"
        >
          <img :src="barres" alt="icon" class="icon" />
          <h5>RESUMEN</h5>
        </div>
      </template>

      <div class="first-tab" :style="{display: this.flexnone }">
        <ResultPostSection />
      </div>
    </TabItem>
    <TabItem>
      <template #name>
        <div class="bar-name-item est" @click="displayTab()">
          <img :src="barest" alt="icon" class="icon" />
          <h5>ESTADÍSTICAS Y ALINEACIÓN</h5>
        </div>
      </template>

      <div class="rs-tab">
        <ResultStats />
      </div>
    </TabItem>
    <TabItem>
      <template #name>
        <div class="bar-name-item gal" @click="displayTab()">
          <img :src="bargal" alt="icon" class="icon" />
          <h5>GALERÍA</h5>
        </div>
      </template>

      <div class="rs-tab galvid">
        <ResultGallery />
      </div>
    </TabItem>
    <TabItem>
      <template #name>
        <div class="bar-name-item vid" @click="displayTab()">
          <img :src="barvid" alt="icon" class="icon" />
          <h5>EN VIVO</h5>
        </div>
      </template>

      <div class="rs-tab galvid">
        <ResultVideosGal />
      </div>
    </TabItem>
  </Tabs>
</div>
</div>
</template>

<script>
import { Tabs, TabItem } from "vue-material-tabs";
import ResultPostSection from "../components/ResultPostSection";
import ResultStats from "../components/ResultStats";
import ResultGallery from "../components/ResultGallery";
import ResultVideosGal from "../components/ResultVideosGal";
import { mapState, mapActions, mapMutations } from 'vuex';

import barres from "../assets/bar-res.png";
import barest from "../assets/bar-est.png";
import bargal from "../assets/bar-gal.png";
import barvid from "../assets/bar-vid.png";
export default {
  data() {
    return {
      barres: barres,
      barest: barest,
      bargal: bargal,
      barvid: barvid,
      isActive: true,
      isLEPXM: false,
      NewData: [],
      tituloTab: 'mx',
      flexnone: 'flex!important'
    };
  },
  components: {
    Tabs,
    TabItem,
    ResultStats,
    ResultPostSection,
    ResultGallery,
    ResultVideosGal,
  },
  async beforeMount() {
    let token = await this.$store.dispatch("admin/getToken");
    this.$store.dispatch("admin/decode", token);
  },
  created(){
    this.findByIdTitle();
  },
  computed: {
    /*tliga(){return  this.$store.getters["calendar2/data2"];},*/
    titulotab() {
     let liga = this.$store.getters["calendar2/data2"];
      if(liga.id_liga == 3){
         this.tituloTab = 'EN VIVO';
      }else if(liga.id_liga == 2){
         this.tituloTab ='EN VIVO';
      }else if(liga.id_liga == 1){
         this.tituloTab = 'VIDEO';
      }
        return this.tituloTab;
    }
  },
  methods: {
    ...mapActions("calendar2", ["findByIdTitle"]),

    displayTab() {
      return document.getElementById("tabactiva").classList.remove("activa"),
      this.flexnone = 'none!important',
      document.getElementById("primer-equipo").style.display = 'none!important',
      document.getElementById('primer-equipo').style.maxHeight = 0,
      document.getElementById('primer-equipo').style.maxWidth = 0;
    },
    alturaMaxima() {
      this.flexnone = 'flex!important',
      document.getElementById("primer-equipo").style.maxHeight = "fit-content";
      document.getElementById("primer-equipo").style.maxWidth = "fit-content";
    },
    
    changeVideoTitle(liga) {
      if(liga = "Liga Premier"){
        return 'EN VIVO';
      }else if(liga = "Liga TDP"){
        return 'EN VIVO';
      }else if(liga = "Liga BBVA Expansión mx"){
        return 'VIDEO';
      }
    },
/*Liga BBVA Expansión mx */
    findByIdTitle: async function (){
      let idm = this.$route.params.id;
      let result = await this.$store.dispatch("calendar2/findByIdTitle", {
        option: "calendar",
        "id": idm,
      });
      this.info = result;
      return result;
    },
  },
};
</script>

<style scoped>
#primer-equipo {
  display: flex!important;
}
.team-type-header {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.activa {
  color: white !important;
  background: #b70d1d; 
    position: relative;
    top: -0.3vw;
  /*padding-right: 5vw !important;
  background-size: 2.865vw !important;
  background-position-x: 14.115vw !important;
  background-position-y: center;
  background-repeat: no-repeat;*/
  display: flex;
  /*background-image: url("../assets/arrow2.png") !important;*/
}
.activa:hover {
  padding-right: 0;
}
@media (max-width: 768px) {
  .activa {
    background-color: #032a4b;
  }
}
</style>
