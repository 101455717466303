<template>
  <div class="resultados-cont">
    <div class="header-result-cont">
      <img
        :src="url + '/get-tournament-image/' + this.caldata.image_tournament"
        alt="lmx"
        class="liga-logo-cont"
        width="265"
        height="53"
      />
      <h1 class="day-result">
        {{ dataTransform(this.caldata.descrp)}} {{ this.caldata.hr }}
      </h1>
      <div class="est-cap-cont">
        <h2 class="est-cap-result">
          {{ this.caldata.stadium }}
        </h2>
      </div>
      <h3 class="ht-ft">FT</h3>
      <!--VISITA-->
      <div
        class="match-result"
         v-if="this.caldata.type == 'Visitante'"
        v-bind:class="{ 'visita-invert': !visita }"
      >
        <div class="loc-visit-cont"
        v-bind:class="{ 'visita-invert-m': !visita }">
          <div class="team-result">
            <div class="ng-result">
              <h2>CIMARRONES DE SONORA</h2>
              <!--<h5 class="goal-cim">Martial 43'</h5>-->
            </div>
            <img src="../assets/Cimarrones-mx.png" alt="cim" class="logo-result" />
          </div>
          <h1 class="result-cir">{{ this.caldata.result_c }}</h1>
        </div>
        <div class="loc-visit-cont"
        v-bind:class="{ 'visita-invert-m': !visita }">
          <h1 class="result-cir">{{ this.caldata.result_op }}</h1>
          <div class="team-result">
            <img
              :src="url + '/get-calendar-image/' + this.caldata.image"
              alt="cim"
              class="logo-result"
            />
            <div class="ng-result" style="display: flex; justify-content: flex-end;">
              <h2>{{ this.caldata.opname }}</h2>
              <!--<h5 class="goal-cim">Martial 43'</h5>-->
            </div>
          </div>
        </div>
      </div>


      <!--LOCAL-->
      <div
        class="match-result"
         v-else-if="this.caldata.type == 'Local'"
        v-bind:class="{ 'visita-invert': visita }"
      >
        <div class="loc-visit-cont"
        v-bind:class="{ 'visita-invert-m': visita }">
          <div class="team-result">
            <div class="ng-result">
              <h2>CIMARRONES DE SONORA</h2>
              <!--<h5 class="goal-cim">Martial 43'</h5>-->
            </div>
            <img src="../assets/Cimarrones-mx.png" alt="cim" class="logo-result" />
          </div>
          <h1 class="result-cir">{{ this.caldata.result_c }}</h1>
        </div>
        <div class="loc-visit-cont"
        v-bind:class="{ 'visita-invert-m': visita }">
          <h1 class="result-cir">{{ this.caldata.result_op }}</h1>
          <div class="team-result">
            <img
              :src="url + '/get-calendar-image/' + this.caldata.image"
              alt="cim"
              class="logo-result"
            />
            <div class="ng-result" style="display: flex; justify-content: flex-start;">
              <h2>{{ this.caldata.opname }}</h2>
              <!--<h5 class="goal-cim">Martial 43'</h5>-->
            </div>
          </div>
        </div>
      </div>
      <!--<div class="ht-result">HT: 1-1</div>-->
      <!--<SponsorSecW />-->
    </div>
  </div>
</template>

<script>
import SponsorSecW from "../components/widgets/SponsorSecW";
import es from 'dayjs/locale/es'
import dayjs from "dayjs"
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { mapActions } from "vuex";

export default {
  components: {
    SponsorSecW,
  },
  data() {
    return {
      visita: false,
      type:'',
      idm: 0,
    };
  },
  /**async beforeMount() {
    let token = await this.$store.dispatch("admin/getToken");
    this.$store.dispatch("admin/decode", token);
  },**/
  created() {
    this.findById();
  },
  computed: {
    url() {
      return this.$store.getters["main/baseURL"];
    },
    caldata() {
      return this.$store.getters["calendar2/data"];
    },
    user() {
      return this.$store.getters["admin/getIdentity"];
    },
    torneo(){
      let arr =  this.$store.getters["calendar2/data"];
      localStorage.setItem('torneo',arr.name_tournament)
      return arr.name_tournament
    }
  },
  methods: {
    ...mapActions("calendar2", ["findById"]),

    visitaValidate: function() {
      this.type = this.calendar.type
      if(this.type  = "Visitante"){
        this.visita = !this.visita;
      }
      else if(this.type  = "Local"){
        this.visita = this.visita;
      }
    },
    
    dataTransform: function(descrp){
       const dayjs = require('dayjs');
       dayjs.extend(customParseFormat)
       let fa =  dayjs(descrp, 'YYYY-MM-DD').locale(es).format('D-MMMM-YYYY')
       let fa2 =  dayjs(descrp, 'YYYY-MM-DD').locale(es).format('dddd-MM-YYYY')
       let fecha = fa.split('-')
              let yy = fecha[2]
              let mm = fecha[1]
              let dd = fecha[0]
       let fecha2 = fa2.split('-')
              let dd2 = fecha2[0]
        return fecha= dd2 +' '+dd+' '+mm
    },  

    findById: async function () {
      let idm = this.$route.params.id;
      let result = await this.$store.dispatch("calendar2/findById", {
        option: "calendar",
        id: idm,
      });
      //this.info = result;
      return result;
    },
  }, 
};
</script>

<style>
#res-contenido .visita-invert-m .team-result {
    flex-direction: row-reverse;
}
</style>
