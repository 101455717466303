<template>
  <div>
   <!-- <div
      class="video-fuerzas-b"
      v-if="
        videodata4.name_liga == 'Liga Premier' ||
        videodata4.name_liga == 'Liga TDP'
      "
    >
      <div id="fb-root"></div>
      <div
        class="errore"
        v-if="this.videodata4.videos == 'No se encontraron coincidencias'"
      >
        <div class="en-construccion-cont">
          <img
            src="../assets/tarjeta-rojiza.png"
            alt="tarjetiza"
            class="tarjetiza"
          />
          <h3 class="titulo-en-constr">
            <span class="red"> VUELVE EN UN RATO.</span> ESTAMOS CARGANDO LA
            INFORMACIÓN.
          </h3>
        </div>
      </div>
      <div
        class="result-gallery"
        v-for="(vid, key) in videodata4.videos"
        :key="key"
        v-else
      >
        <div class="cont-gal-title">
          <h1 class="result-gal-title">{{ vid.title }}</h1>
        </div>
        <div class="galleries-cont">
<iframe src="https://www.facebook.com/plugins/video.php?height=476&href=https%3A%2F%2Fwww.facebook.com%2FKunoTeletubbie%2Fvideos%2F615251379726389%2F&show_text=false&width=476&t=0" width="476" height="476" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" ></iframe>        </div>
      </div>
    </div>-->

    <div id="video-expansion">
      <div
        class="errore"
        v-if="this.videodata4.videos == 'No se encontraron coincidencias'"
      >
        <div class="en-construccion-cont">
          <img
            src="../assets/tarjeta-rojiza.png"
            alt="tarjetiza"
            class="tarjetiza"
          />
          <h3 class="titulo-en-constr">
            <span class="red"> VUELVE EN UN RATO.</span> ESTAMOS CARGANDO LA
            INFORMACIÓN.
          </h3>
        </div>
      </div>
      <div
        class="result-gallery"
        v-for="(vid, key) in videodata4.videos"
        :key="key"
        v-else
      >
        <div class="cont-gal-title">
          <h1 class="result-gal-title">{{ vid.title }}</h1>
        </div>
        <div class="galleries-cont">
          <div class="video-gal-cont" v-html="vid.url"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueCoreVideoPlayer from "vue-core-video-player";
import Sponsor3 from "../components/widgets/SponsorSecW3";
import es from "dayjs/locale/es";
import relativeTime from "dayjs/plugin/relativeTime";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { mapActions } from "vuex";
import VideoModal from "../components/VideoModal.vue";

export default {
  components: {
    VueCoreVideoPlayer,
    Sponsor3,
    VideoModal,
  },
  data() {
    return {
      isModalVisible13: false,
      modalData13: null,
     
    };
  },
  created() {
    this.findById();
  },
  computed: {
    url() {
      return this.$store.getters["main/baseURL"];
    },
    videodata4() {
      return this.$store.getters["calendar2/data"];
    },
  },
  methods: {
    ...mapActions("calendar2", ["findById"]),

    showModal13(data) {
      this.isModalVisible13 = true;
      this.modalData13 = data;
    },
    closeModal13() {
      this.isModalVisible13 = false;
      this.modalData13 = null;
    },

    videoId: function (url) {
      let match = url.match(/v=([0-9a-z_-]{1,20})/i);
      return match ? match["1"] : false;
    },
    dataTransform: function (date) {
      let fecha = date.split("/");
      let yy = fecha[2];
      let mm = fecha[1];
      let dd = fecha[0];
      return (fecha = yy + "-" + mm + "-" + dd);
    },
    dataSort: function (date) {
      let fecha = date.split("/");
      let yy = fecha[2];
      let mm = fecha[1];
      let dd = fecha[0];
      return (fecha = yy + "" + mm + "" + dd);
    },

    newsdateago: function (date) {
      const dayjs = require("dayjs");
      dayjs.extend(customParseFormat);
      dayjs.extend(relativeTime);
      let newsdata = dayjs(date, "YYYY-MM-DD").locale(es).fromNow(true);
      return newsdata;
    },

    findById: async function () {
      let idm = this.$route.params.id;
      let result = await this.$store.dispatch("calendar2/findById", {
        option: "calendar",
        id: idm,
      });
      //this.info = result;
      return result;
    },

    filterTitle: function (title) {
      let result = title.replaceAll(" ", "-");
      return result;
    },
  },
};
</script>
<style scoped>
.video-gal-cont {
  width: 100%;
}
.video-gal-cont iframe {
    width: 47vw!important;
    height: 30vw!important;
}
@media (max-width:768px) { 
}
</style>